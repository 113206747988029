@define-mixin highlight {
  background-color: #2b2d42;
  color: #ffffff;
  outline: none;
}

.container {
  display: flex;
  justify-content: center;
}

.wrapper {
  position: fixed;
  max-width: 320px;
}

.input {
  box-sizing: border-box;
  border: 1px solid #e4e8ea;

  padding: 0 12px;

  box-shadow: 0px 1px 1px 0px #f1f1f1;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  color: var(--text-color);

  text-overflow: ellipsis;
}

.input::-webkit-input-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.input::-moz-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.input:-ms-input-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.input,
.dropdownItem {
  width: 100%;
}

.input:hover,
.dropdownItem:hover {
  @mixin highlight;
}

.dropdownItem {
  padding: 0;
  position: relative;
  box-sizing: border-box;
  background-color: #f6f9fa;

  border: 1px solid #e4e8ea;
  border-radius: 4px;
}

.dropdownAnchor {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;

  padding: 12px 20px 12px 12px;
  border-radius: 4px;
  cursor: pointer;

  /* Let anchor & parents resize if text too long - necesary for mobile */
  word-wrap: break-word;
}

.dropdown {
  list-style: none;
  padding: 0;
  overflow-y: scroll;
  z-index: 10;
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 4;
}

.optionArrow {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
}

.invalid {
  border: 1px solid var(--color-error);
}

.highlight {
  @mixin highlight;
}

.hidden {
  display: none;
}

@media screen and (max-width: 48em) {
  .wrapper {
    position: absolute;
    max-width: none;
    padding: 0 16px;
  }

  .dropdown {
    padding: 0 16px 100px 16px;
  }

  .input,
  .dropdownItem {
    min-height: 60px;
    width: 100%;
  }
}

/* Use a min font size for screens narrower than 320px */
@media screen and (max-width: 20rem) {
  .input,
  .dropdownItem {
    font-size: 1rem;
  }
}

/* Use a max font size for screens wider than 728px */
@media screen and (min-width: 48em) {
  .input,
  .dropdownItem {
    font-size: 1.125rem;
  }
}
