.optionLabel {
  display: block;
  padding: 1em 2em 1em 2em;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.optionText {
  flex-grow: 1;
}

@media only screen and (min-width: 960px) {
  .ieGroup {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .ieOption {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media only screen and (max-width: 959.95px) {
  .ieGroup {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .ieOption {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .ieGroup {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }

  .ieOption {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }
}
