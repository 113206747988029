@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/flexboxgrid/dist/flexboxgrid.css";

@import url("https://fonts.googleapis.com/css?family=Paytone+One");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900&display=swap");

html {
  height: auto;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior: contain;
  height: 100vh;
}

div#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Override flexboxgrid values */
.container {
  height: 100%;
  width: 100%;
}

div#CybotCookiebotDialog {
  box-shadow: 0 0 29.8px 5.3px rgba(0, 0, 0, 0.1);

  & div,
  & a {
    font-family: Roboto;
  }

  & #CybotCookiebotDialogBodyContentTitle {
    font-family: Roboto;
    font-size: 20px;
  }

  & a#CybotCookiebotDialogBodyLevelButtonAccept {
    background-color: #2b55c5;
    border: 1px solid #2b55c5;
    padding: 5px 2px;
    margin-top: 5px;
    border-radius: 13.5px;
    font-weight: 700;

    &:hover {
      color: #2b55c5;
      background-color: #fff;
    }
  }

  & #CybotCookiebotDialogBodyLevelDetailsWrapper {
    background: transparent;
    border: none;
  }

  & #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border: none;
  }
}
