input[placeholder] {
  text-overflow: ellipsis;
}

.input {
  height: 34px;
  padding: 0 12px;

  border: 2px solid #b0c0da;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #2b2d42;

  text-overflow: ellipsis;
}

.input::-webkit-input-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.input::-moz-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.input:-ms-input-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.invalid {
  border: 1px solid var(--color-error);
}

@media screen and (max-width: 320px) {
  .input {
    height: 30px;
  }
}
