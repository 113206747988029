.row {
  min-height: 4em;
}

.optionText {
  width: 194px;
  height: 254px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: right;
  color: #2b2a28;
}

@media only screen and (max-width: 959.95px) {
  .optionText {
    margin-left: 0;
    line-height: 1;
  }
}

@media only screen and (max-width: 320px) {
  .optionText {
    font-size: 11px;
  }
}
