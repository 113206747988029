.item {
  display: flex;
  min-height: 4em;
  align-items: center;
  padding: 4px;
  user-select: none;
}

.item:hover {
  cursor: pointer;
}

.text {
  margin: 0 1em 0 0.5em;
}

@media screen and (max-width: 599.95px) {
  .item {
    min-height: 2.5em;
  }

  .text {
    font-size: 14px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 320px) {
  .item {
    height: 36px;
    min-height: auto;
    box-sizing: border-box;
  }

  .text {
    font-size: 11px;
  }
}
