.container {
  width: 100%;
}

.group {
  margin: 1.25em 0 1.25em 0;
}

@media (max-width: 48rem) {
  .group {
    border-bottom: 2px solid rgba(var(--text-color), 0.1);
    margin-bottom: -50px;
  }
}
