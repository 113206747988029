.markdown {
  width: 100%;
  font-weight: 300;
  font-size: 24px;
  box-sizing: border-box;
  padding-left: 350px;
  padding-right: 400px;
}

.markdown p {
  text-align: left;
}

@media only screen and (max-width: 959.95px) {
  .markdown {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: inherit;
  }
}

@media only screen and (min-width: 960px) {
  .markdown {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media only screen and (min-width: 1280px) {
  .markdown {
    padding-left: 350px;
    padding-right: 400px;
  }
}

@media only screen and (max-width: 599.95px) {
  .markdown {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    font-size: 16px;
    box-sizing: inherit;
  }
}

@media screen and (max-width: 320px) {
  .markdown {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }
}
