.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.textAreaCounterWrapper {
  width: 50%;
  position: relative;
}

.textarea {
  width: 100%;
  box-sizing: border-box;

  padding: 0.75em 1em 2.75em;

  resize: none;

  border: 1px solid #f1f1f1;
  box-shadow: 0px 1px 1px 0px #f1f1f1;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: var(--text-color);

  &::-webkit-input-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }

  &::-moz-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }

  &:-ms-input-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }
}

.invalid {
  border: 1px solid var(--color-error);
}

.charCounter {
  position: absolute;
  left: 1em;
  bottom: 1em;

  border: 0;

  font-size: 13px;
  font-weight: 700;

  color: var(--color-text-gray);
}

.charCounter.invalid {
  color: var(--color-error);
}

@media only screen and (max-width: 959.95px) {
  .wrapper {
    padding: 0 16px;
    box-sizing: inherit;
  }

  .textAreaCounterWrapper {
    width: 100%;
  }
}
