@import url(https://fonts.googleapis.com/css?family=Paytone+One);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,700,900&display=swap);
input[placeholder] {
  text-overflow: ellipsis;
}

.Input_input__3PNbO {
  height: 34px;
  padding: 0 12px;

  border: 2px solid #b0c0da;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #2b2d42;

  text-overflow: ellipsis;
}

.Input_input__3PNbO::-webkit-input-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.Input_input__3PNbO::-moz-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.Input_input__3PNbO:-ms-input-placeholder {
  font-style: italic;
  color: #2b2d42;
  font-size: 14px;
}

.Input_invalid__2u9yB {
  border: 1px solid var(--color-error);
}

@media screen and (max-width: 320px) {
  .Input_input__3PNbO {
    height: 30px;
  }
}

.TextArea_wrapper__3RvCP {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.TextArea_textAreaCounterWrapper__2Sj9v {
  width: 50%;
  position: relative;
}

.TextArea_textarea__3TM_4 {
  width: 100%;
  box-sizing: border-box;

  padding: 0.75em 1em 2.75em;

  resize: none;

  border: 1px solid #f1f1f1;
  box-shadow: 0px 1px 1px 0px #f1f1f1;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  color: var(--text-color);

  &::-webkit-input-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }

  &::-moz-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }

  &:-ms-input-placeholder {
    font-style: italic;
    color: rgba(var(--text-color), 0.2);
  }
}

.TextArea_invalid__PgNSq {
  border: 1px solid var(--color-error);
}

.TextArea_charCounter__36LDm {
  position: absolute;
  left: 1em;
  bottom: 1em;

  border: 0;

  font-size: 13px;
  font-weight: 700;

  color: var(--color-text-gray);
}

.TextArea_charCounter__36LDm.TextArea_invalid__PgNSq {
  color: var(--color-error);
}

@media only screen and (max-width: 959.95px) {
  .TextArea_wrapper__3RvCP {
    padding: 0 16px;
    box-sizing: inherit;
  }

  .TextArea_textAreaCounterWrapper__2Sj9v {
    width: 100%;
  }
}

@define-mixin highlight {
  background-color: #2b2d42;
  color: #ffffff;
  outline: none;
}

.SearchableList_container__AA55d {
  display: flex;
  justify-content: center;
}

.SearchableList_wrapper__9iCmX {
  position: fixed;
  max-width: 320px;
}

.SearchableList_input__22JOT {
  box-sizing: border-box;
  border: 1px solid #e4e8ea;

  padding: 0 12px;

  box-shadow: 0px 1px 1px 0px #f1f1f1;
  border-radius: 4px;
  outline: none;

  font-family: "Roboto", sans-serif;
  color: var(--text-color);

  text-overflow: ellipsis;
}

.SearchableList_input__22JOT::-webkit-input-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.SearchableList_input__22JOT::-moz-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.SearchableList_input__22JOT:-ms-input-placeholder {
  font-style: italic;
  color: rgba(var(--text-color), 0.2);
}

.SearchableList_input__22JOT,
.SearchableList_dropdownItem__4n_vj {
  width: 100%;
}

.SearchableList_input__22JOT:hover,
.SearchableList_dropdownItem__4n_vj:hover {
  @mixin highlight;
}

.SearchableList_dropdownItem__4n_vj {
  padding: 0;
  position: relative;
  box-sizing: border-box;
  background-color: #f6f9fa;

  border: 1px solid #e4e8ea;
  border-radius: 4px;
}

.SearchableList_dropdownAnchor__IfOb_ {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;

  padding: 12px 20px 12px 12px;
  border-radius: 4px;
  cursor: pointer;

  /* Let anchor & parents resize if text too long - necesary for mobile */
  word-wrap: break-word;
}

.SearchableList_dropdown__1ODA7 {
  list-style: none;
  padding: 0;
  overflow-y: scroll;
  z-index: 10;
  position: absolute;
  margin: 0 auto;
  right: 0;
  left: 0;
  top: 4;
}

.SearchableList_optionArrow__3Ot18 {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 10px;
}

.SearchableList_invalid__2QRs0 {
  border: 1px solid var(--color-error);
}

.SearchableList_highlight__3XN9V {
  @mixin highlight;
}

.SearchableList_hidden__2WIyS {
  display: none;
}

@media screen and (max-width: 48em) {
  .SearchableList_wrapper__9iCmX {
    position: absolute;
    max-width: none;
    padding: 0 16px;
  }

  .SearchableList_dropdown__1ODA7 {
    padding: 0 16px 100px 16px;
  }

  .SearchableList_input__22JOT,
  .SearchableList_dropdownItem__4n_vj {
    min-height: 60px;
    width: 100%;
  }
}

/* Use a min font size for screens narrower than 320px */
@media screen and (max-width: 20rem) {
  .SearchableList_input__22JOT,
  .SearchableList_dropdownItem__4n_vj {
    font-size: 1rem;
  }
}

/* Use a max font size for screens wider than 728px */
@media screen and (min-width: 48em) {
  .SearchableList_input__22JOT,
  .SearchableList_dropdownItem__4n_vj {
    font-size: 1.125rem;
  }
}

.CheckboxList_item__3nnNv {
  display: flex;
  min-height: 4em;
  align-items: center;
  padding: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.CheckboxList_text__2i9pN {
  margin: 0 1em 0 0.5em;
}

@media screen and (max-width: 599.95px) {
  .CheckboxList_item__3nnNv {
    height: 46px;
    padding: 0;
  }

  .CheckboxList_text__2i9pN {
    margin: 0 1em 0 0;
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .CheckboxList_item__3nnNv {
    height: 36px;
    min-height: auto;
  }

  .CheckboxList_text__2i9pN {
    font-size: 11px;
  }
}

.CheckboxGrid_optionLabel__3x_2b {
  display: block;
  padding: 1em 2em 1em 2em;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.CheckboxGrid_optionText__ZizVc {
  flex-grow: 1;
}

@media only screen and (min-width: 960px) {
  .CheckboxGrid_ieGroup__LFUqL {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .CheckboxGrid_ieOption__d-SaT {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media only screen and (max-width: 959.95px) {
  .CheckboxGrid_ieGroup__LFUqL {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .CheckboxGrid_ieOption__d-SaT {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .CheckboxGrid_ieGroup__LFUqL {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }

  .CheckboxGrid_ieOption__d-SaT {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }
}

.RadioList_item__3bWZu {
  display: flex;
  min-height: 4em;
  align-items: center;
  padding: 4px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RadioList_item__3bWZu:hover {
  cursor: pointer;
}

.RadioList_text__1tl31 {
  margin: 0 1em 0 0.5em;
}

@media screen and (max-width: 599.95px) {
  .RadioList_item__3bWZu {
    min-height: 2.5em;
  }

  .RadioList_text__1tl31 {
    font-size: 14px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 320px) {
  .RadioList_item__3bWZu {
    height: 36px;
    min-height: auto;
    box-sizing: border-box;
  }

  .RadioList_text__1tl31 {
    font-size: 11px;
  }
}

.RadioGrid_optionLabel__1juh9 {
  display: block;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: calc(4em - 10px);
}

.RadioGrid_toggleAllLabel__j-nJG {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .optionText {
  margin-left: 0.5em;
} */

@media only screen and (min-width: 960px) {
  .RadioGrid_ieGroup__1ai0t {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .RadioGrid_ieOption__ZvjUo {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media only screen and (max-width: 959.95px) {
  .RadioGrid_ieGroup__1ai0t {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .RadioGrid_ieOption__ZvjUo {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .RadioGrid_ieGroup__1ai0t {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }

  .RadioGrid_ieOption__ZvjUo {
    padding-left: 350px !important;
    padding-right: 400px !important;
  }
}

.RatingList_row__2Ba-F {
  min-height: 4em;
}

.RatingList_optionText__3OAwm {
  width: 194px;
  height: 254px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: right;
  color: #2b2a28;
}

@media only screen and (max-width: 959.95px) {
  .RatingList_optionText__3OAwm {
    margin-left: 0;
    line-height: 1;
  }
}

@media only screen and (max-width: 320px) {
  .RatingList_optionText__3OAwm {
    font-size: 11px;
  }
}

.RatingGrid_container__1_kN3 {
  width: 100%;
}

.RatingGrid_group__24nuZ {
  margin: 1.25em 0 1.25em 0;
}

@media (max-width: 48rem) {
  .RatingGrid_group__24nuZ {
    border-bottom: 2px solid rgba(var(--text-color), 0.1);
    margin-bottom: -50px;
  }
}

.Image_imageContainer__BluFk {
  display: flex;
  justify-content: center;
}

.Image_image__37gzF {
  width: 300px;
  height: 300px;
}

.ReadOnly_markdown__C7wRc {
  width: 100%;
  font-weight: 300;
  font-size: 24px;
  box-sizing: border-box;
  padding-left: 350px;
  padding-right: 400px;
}

.ReadOnly_markdown__C7wRc p {
  text-align: left;
}

@media only screen and (max-width: 959.95px) {
  .ReadOnly_markdown__C7wRc {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: inherit;
  }
}

@media only screen and (min-width: 960px) {
  .ReadOnly_markdown__C7wRc {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media only screen and (min-width: 1280px) {
  .ReadOnly_markdown__C7wRc {
    padding-left: 350px;
    padding-right: 400px;
  }
}

@media only screen and (max-width: 599.95px) {
  .ReadOnly_markdown__C7wRc {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    font-size: 16px;
    box-sizing: inherit;
  }
}

@media screen and (max-width: 320px) {
  .ReadOnly_markdown__C7wRc {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }
}

html {
  height: auto;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  height: 100vh;
}

div#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* Override flexboxgrid values */
.container {
  height: 100%;
  width: 100%;
}

div#CybotCookiebotDialog {
  box-shadow: 0 0 29.8px 5.3px rgba(0, 0, 0, 0.1);

  & div,
  & a {
    font-family: Roboto;
  }

  & #CybotCookiebotDialogBodyContentTitle {
    font-family: Roboto;
    font-size: 20px;
  }

  & a#CybotCookiebotDialogBodyLevelButtonAccept {
    background-color: #2b55c5;
    border: 1px solid #2b55c5;
    padding: 5px 2px;
    margin-top: 5px;
    border-radius: 13.5px;
    font-weight: 700;

    &:hover {
      color: #2b55c5;
      background-color: #fff;
    }
  }

  & #CybotCookiebotDialogBodyLevelDetailsWrapper {
    background: transparent;
    border: none;
  }

  & #CybotCookiebotDialogBodyLevelButtonsSelectPane {
    border: none;
  }
}

